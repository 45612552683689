import React, {useState} from 'react';
import {Link, graphql} from 'gatsby';
import moment from 'moment';
import classNames from 'classnames';
import Img from 'gatsby-image';
import {Button, Modal, IconButton, Icon} from 'rsuite';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Bio from '../components/bio';

import './index.scss';
const tags = ['React', 'development', 'TypeScript', 'AWS'];

const BlogIndex = ({data, location}) => {
  const siteTitle = data.site.siteMetadata.title;
  const [isModal, setIsModal] = useState(false);
  const [sortTags, setTags] = useState([]);
  let posts = data.allMarkdownRemark.edges;

  // タグでフィルタリング
  if (sortTags.length) {
    let _posts = [];
    sortTags.forEach((statement) => {
      const target = posts.filter((post) => {
        return post.node.frontmatter.tags.indexOf(statement) !== -1;
      });

      _posts.push(...target);
    });

    posts = _posts;
  }
  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" />
      <div
        className={classNames('tags-count', {
          column: sortTags.length,
        })}
      >
        <div style={{display: 'flex'}}>
          <p className="tag-type">
            {sortTags.length ? `${sortTags.join()}: ` : 'すべての投稿: '}
          </p>
          <p>{posts.length}件</p>
        </div>
      </div>
      <div className="w__posts">
        {posts.map(({node}) => {
          const title = node.frontmatter.title || node.fields.slug;

          return (
            <div
              className={classNames('card', {
                none: node.fields.slug === '/profile/',
              })}
            >
              <Link to={node.fields.slug}>
                <article key={node.fields.slug}>
                  <header>
                    <Img
                      sizes={
                        node.frontmatter.featuredImage.childImageSharp.sizes
                      }
                    />

                    <div className="title-section">
                      <span className="title">{title}</span>
                      <div className="w__tag">
                        {node.frontmatter.tags.map((tag) => {
                          return <span className="tag">#{tag}</span>;
                        })}
                      </div>
                      <p className="posted-at">
                        {`posted at:
                  ${moment(node.frontmatter.date).format('YYYY/MM/DD')}
                  `}
                      </p>
                    </div>
                  </header>
                  <section>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: node.frontmatter.description || node.excerpt,
                      }}
                    />
                  </section>
                </article>
              </Link>
              <div className="w__button">
                <Link to={node.fields.slug}>
                  <Button>この記事を読む</Button>
                </Link>
              </div>
            </div>
          );
        })}
        <button
          className="tags-button"
          onClick={() => {
            setIsModal(!isModal);
          }}
        >
          tags
        </button>
      </div>
      <footer className="main-footer">
        <Bio />
      </footer>
      <Modal
        full
        show={isModal}
        onHide={() => {
          setIsModal();
        }}
      >
        <Modal.Header>
          <Modal.Title>タグで絞り込む</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {tags.map((tag) => {
            return (
              <div
                className={classNames('tag', {
                  selected: sortTags.indexOf(tag) !== -1,
                })}
              >
                <button
                  className="w__tag-button"
                  onClick={(e, d) => {
                    console.log(sortTags);
                    const statement = sortTags;
                    const clickedTag = e.target.innerText;
                    if (sortTags.indexOf(tag) === -1) {
                      statement.push(tag);
                      setTags(statement);
                      setIsModal(false);
                    } else {
                      const statement = sortTags.filter((targetTag) => {
                        return clickedTag !== targetTag;
                      });
                      setTags(statement);
                      setIsModal(false);
                    }
                  }}
                >
                  <IconButton
                    appearance="ghost"
                    icon={
                      <Icon className="fill-color" icon="hashtag" size="lg" />
                    }
                  >
                    <span>{tag}</span>
                  </IconButton>
                </button>
              </div>
            );
          })}
        </Modal.Body>
        <Modal.Footer>
          <div
            className={classNames('w__reset-button', {
              disabled: !sortTags.length,
            })}
          >
            <button
              className={classNames('w__reset-button', {
                disabled: !sortTags.length,
              })}
              disabled={!sortTags.length}
              onClick={() => {
                setTags([]);
                setIsModal(false);
              }}
            >
              <Button>絞り込みをリセット</Button>
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            tags
            featuredImage {
              childImageSharp {
                sizes(maxWidth: 400) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    }
  }
`;
